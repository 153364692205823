<template>
  <b-modal
    class="bmodal"
    size="md"
    id="memberPopupModal"
    ref="modalMemberPopup"
    :title="modal_title"
    ok-variant="primary"
    centered
    :hide-footer="false"
    @ok="handleOkClick($event)"
    @show="handleShow()"
    :no-close-on-backdrop="noCloseOnBackdrop"
    ok-only
    :ok-title="i18n['member-popup'].tcOk"
  >
    <div class="modalcontainer">
      <div v-html="htmlMessage"></div>
      <div class="modalfooter">
        <b-form-checkbox
          id="cb-dont"
          v-model="dontShow"
          name="cb-dont"
          value="true"
          unchecked-value="false"
          class="mt-5"
        >
          {{ i18n['member-popup'].tcDoNotShowMessageAgain }}
        </b-form-checkbox>
      </div>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
import { mapActions } from 'vuex'

export default {
  name: 'member-popup',
  props: {
    htmlMessage: {
      type: String,
      default: '',
    },
    i18n: {
      type: Object,
      default: () => {
        return {
          'member-popup': {
            tcDoNotShowMessageAgain: 'Do not show this message again.',
            tcOk: 'Ok',
          },
        }
      },
    },
    pop_key: {
      type: String,
      default: '',
    },
    modal_title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dontShow: 'false',
      noCloseOnBackdrop: false,
    }
  },
  methods: {
    ...mapActions({}),
    async handleOkClick(e) {
      this.$emit('setClearMessage', {
        dontShow: this.dontShow,
        pop_key: this.pop_key,
      })
      this.closeModal()
    },
    handleOk() {
      this.$emit('setClearMessage', {
        dontShow: this.dontShow,
        pop_key: this.pop_key,
      })
      this.closeModal()
    },
    async handleShow() {},
    closeModal() {
      this.$refs.modalMemberPopup.hide()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.modalcontainer {
  .modalfooter {
    text-align: right;
    font-size: 75%;
  }
}
</style>
